<template>
	<div class="vmodal vmodal--ease">
		<button class="vmodal__close" @click="$emit('close')">
			<v-icon name="times"></v-icon>
		</button>

		<div class="vmodal__container">
			<div class="vmodal__main">
				<div class="form">
					<div class="form__head">
						<div class="form__icon">
							<v-icon name="cubes"></v-icon>
						</div>
						<div class="form__title">{{hasEdit ? 'Edit' : 'Create'}} level option</div>
					</div>

					<div class="grid">
						<div class="grid__coll grid__coll--6">
							<div class="form__item">
								<form-input v-model="key"
										:class="{'has-disabled':  hasEdit}"
										label="Key"
										rules="required"
										@enter="onSave"
										name="key"></form-input>
							</div>

							<div class="form__item">
								<form-img v-model="image"
										label="Add icon"
										name="icon"></form-img>
							</div>
						</div>

						<div class="grid__coll grid__coll--6">
							<div class="form__item">
								<form-input v-model="min"
										type="number"
										label="Start position"
										rules="required"
										@enter="onSave"
										name="min"></form-input>
							</div>

							<div class="form__item">
								<form-input v-model="max"
										type="number"
										label="End position"
										rules="required"
										@enter="onSave"
										name="max"></form-input>
							</div>

							<div class="form__item">
								<form-input v-model="price"
										type="number"
										label="Price, $"
										rules="required"
										@enter="onSave"
										translate="Цена за 1 очко."
										:hasTranslate="true"
										name="price"></form-input>
							</div>

							<div class="form__item">
								<form-input v-model="time"
										type="number"
										label="Time, m"
										rules="required"
										@enter="onSave"
										translate="Время за 1 очко. При установке времени исполнения, следует учитывать что система рассчитывает дедлай с учотом 8-ми часового робочого дня (1 роб. День = 480 минут)."
										:hasTranslate="true"
										name="time"></form-input>
							</div>
						</div>
					</div>

					<div class="grid">
						<div class="grid__coll grid__coll--6">

						</div>

						<div class="grid__coll grid__coll--6">

						</div>
					</div>

					<div class="form__footer">
						<button class="btn"
								@click="onSave"><span>{{hasEdit ? 'Edit' : 'Create'}}</span></button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import 'vue-awesome/icons/times';
	import 'vue-awesome/icons/cubes';
	import VIcon from 'vue-awesome/components/Icon';

	import FormInput from '@form/FormInput';
	import FormImg from '@form/FormImg';

	import { createNamespacedHelpers } from 'vuex';
	const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('services');

	export default {
		name: 'service-data-range-save',
		components: {
			VIcon,
			FormInput,
			FormImg
		},
		props:['option', 'lang'],
		data(){
			return {
				key: null,
				image: null,
				price: null,
				time: null,
				min: null,
				max: null
			}
		},

		computed: {
			hasEdit(){
				return !!this.option;
			},
		},

		created(){
			if(this.hasEdit) this.buildData();
		},

		methods: {
			...mapMutations([
				'SAVE_DATA_RANGE'
			]),

			onSave(){
				this.$validator.validate().then(async result => {
					if (result) {
						this.SAVE_DATA_RANGE({
							id: this.hasEdit ? this.option.id : null,
							key: this.key,
							position: 0,
							text: {[this.lang.code]: ''},
							is_selected: false,
							additional:{
								image: this.image,
								price: this.price,
								time: this.time,
								min: this.min,
								max: this.max,
							},

						});
						this.$emit('close');
					}
				});
			},

			buildData () {
				let option = this.option;

				this.key = option.key;
				this.image = option.additional.image;
				this.price = option.additional.price;
				this.time = option.additional.time;
				this.min = option.additional.min;
				this.max = option.additional.max;
			}
		}


	};
</script>

<style scoped lang=scss>

</style>
