<template>
	<div class="section" v-if="hasLoadService">
		<div class="container">
			<div class="section__head">
				<div class="title title--section service__mainTitle">
					<div class="service__logo" :style="{'background-image': `url(${require('@img/card_bg.png')}`}">
						<img :src="service.item.game.icon" alt="">
						<span>{{service.item.game.title}}</span>
					</div>
					<div class="service__title">
						<form-text-edit	v-model="title"
								class="has-service"
								:label="`Service title (${lang.code})`"
								:translate="service.item.translations.title ?
									service.item.translations.title[this.DEFAULT_LANG.code] : null"
								:hasTranslate="hasTranslate"
								name="title"></form-text-edit>
					</div>
				</div>
				<div class="section__options">
					<div class="section__option" v-if="hasEdit">
						<div class="btn btn--icon service__delete" @click="onDelete">
							<v-icon name="trash-alt"></v-icon>
						</div>
					</div>
					<div class="section__option">
						<div class="service__status">
							<form-checkbox v-model="is_active"
									class="_m-0"
									label="Active"
									name="is_active"></form-checkbox>
						</div>
					</div>
					<div class="section__option">
						<form-input v-model="key"
								class="_m-0"
								label="Service url key"
								name="key"></form-input>
					</div>
					<div class="section__option has-lang">
						<form-select v-model="lang"
								class="_m-0"
								:notEmpty="true"
								:options="languages"
								label="Language"
								option_label="name"
								option_id="code"
								name="language"></form-select>
					</div>

					<div class="section__option">
						<router-link class="btn btn--secong" :to="`/games/${service.item.game.id}`">Back to services</router-link>
					</div>
				</div>
			</div>

			<div class="service">
				<div class="service__main">
					<div class="service__empty" v-if="!hasConstructorType">
						<info-msg type="warning">
							<p>Select calculator type</p>
						</info-msg>
					</div>

					<div class="service__data" v-if="hasConstructorType">
						<service-data :lang="lang"></service-data>
					</div>
				</div>

				<div class="service__side">
					<div class="service__item">
						<structure-index></structure-index>
					</div>

					<div class="service__item">
						<service-namespaced :lang="lang"></service-namespaced>
					</div>

					<div class="service__item">
						<params-index :lang="lang"></params-index>
					</div>

					<div class="service__item">
						<options-index :lang="lang"></options-index>
					</div>

					<div class="service__item">
						<regions-index :lang="lang"></regions-index>
					</div>

					<div class="service__item">
						<champions-index :lang="lang"></champions-index>
					</div>

					<div class="service__item">
						<service-seo :lang="lang"></service-seo>
					</div>
				</div>
			</div>

			<div class="service__save">
				<button class="btn"
						:class="{'has-loading': hasLoading, 'has-disabled': !hasConstructorType || !title || !key}"
						@click="onSave"><span>{{hasEdit ? 'Edit' : 'Create'}}</span></button>
			</div>

			<div class="service__preview"
					:class="{'has-disabled': !hasConstructorType}"
					@click="onPreview">
				<v-icon name="calculator"></v-icon>
			</div>
		</div>
	</div>
</template>

<script>
	import 'vue-awesome/icons/trash-alt';
	import 'vue-awesome/icons/calculator';
	import VIcon from 'vue-awesome/components/Icon';

	import FormSelect from '@form/FormSelect';
	import FormInput from '@form/FormInput';
	import FormCheckbox from '@form/FormCheckbox';
	import FormTextEdit from '@form/FormTextEdit';
	import InfoMsg from '@components/InfoMsg';


	import StructureIndex from './structure/StructureIndex';
	import ServiceNamespaced from './ServiceNamespaced';
	import ServiceSeo from './ServiceSeo';
	import ServiceData from './ServiceData';
	import ServicePreview from './ServicePreview';
	import ParamsIndex from './orderParams/ParamsIndex';
	import OptionsIndex from './serviceOptions/OptionsIndex';
	import RegionsIndex from './regions/RegionsIndex';
	import ChampionsIndex from './champions/ChampionsIndex';

	import { createNamespacedHelpers } from 'vuex';
	const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('services');

	export default {
		name: 'service-index',
		components: {
			FormSelect,
			FormInput,
			FormTextEdit,
			FormCheckbox,
			StructureIndex,
			RegionsIndex,
			ServiceNamespaced,
			ServiceSeo,
			ServiceData,
			ParamsIndex,
			OptionsIndex,
			ChampionsIndex,
			InfoMsg,
			VIcon
		},
		props:['id'],
		data(){
			return{
				lang: null,
				game_id: null,

				hasLoadService: false,
				hasLoadGame: false,
				hasLoadInitData: false,
				hasLoading: false
			}
		},

		computed:{
			...mapState([
				'service'
			]),
			DEFAULT_LANG(){
				return this.$store.state.DEFAULT_LANG;
			},
			languages(){
				return this.$store.state.languages.list;
			},

			hasEdit(){
				return !!this.id;
			},

			hasTranslate(){
				return 	this.lang.code !== this.DEFAULT_LANG.code
			},

			hasConstructorType(){
				return this.service.item.layout_key;
			},

			title:{
				get(){
					if (!this.service.item.translations.title) this.setTranslationsProp('title');
					return this.service.item.translations.title[this.lang.code]
				},
				set(val){
					this.SET_SERVICE_TITLE({
						lang: this.lang.code,
						title: val
					})
				}
			},

			key:{
				get(){
					return this.service.item.key
				},
				set(val){
					this.SET_SERVICE_KEY(val)
				}
			},

			is_active:{
				get(){
					return !!+this.service.item.is_active
				},
				set(val){
					this.SET_SERVICE_ACTIVE(val);
				}
			}
		},

		created(){
			this.lang = this.DEFAULT_LANG;
			this.game_id = this.$route.query.game_id;

			if (this.game_id){
				this.resetStore();
				this.createBaseService();

			} else{
				this.getService();
			}
		},

		methods: {
			...mapMutations([
				'SET_SERVICE_TITLE',
				'SET_SERVICE_KEY',
				'SET_SERVICE_ACTIVE',
				'RESET_STORE',
				'SET_TRANSLATIONS_PROP'
			]),
			...mapActions([
				'GET_SERVICE',
				'GET_GAME',
				'SAVE_SERVICE',
				'DELETE_SERVICE'
			]),

			onSave(){
				this.hasLoading = true;

				this.SAVE_SERVICE().then(resp => {
					this.hasLoading = false;

					if(!this.hasEdit){
						this.$router.push(`/service/${resp.item.id}`);
					} else {
						// this.getService();
						this.onPreview();
					}


				}).catch(() => {
					this.hasLoading = false;
				})
			},

			onPreview(){
				this.$modal.show(ServicePreview, {lang: this.lang}, $MODAL_OPTIONS.big);
			},

			onDelete(){
				if (confirm('Do you want delete this service?')){
					this.DELETE_SERVICE().then(() => {
						this.$router.push(`/games/${this.service.item.game.id}`);
					})
				}
			},

			resetStore(){
				this.RESET_STORE();
			},

			createBaseService(){
				this.GET_GAME(this.game_id).then(() => {
					this.hasLoadService = true
				})
			},

			getService(){
				this.GET_SERVICE(this.id).then(() => {
					this.hasLoadService = true
				})
			},

			setTranslationsProp(key){
				this.SET_TRANSLATIONS_PROP(key)
			}
		}
	};
</script>

<style scoped lang=scss>
	.service{
		display: flex;
		align-items: flex-start;
		&__data{
			margin-bottom: 30px;
		}
		&__mainTitle{
			display: flex;
			align-items: center;
		}
		&__item{
			margin-bottom: 50px;
		}
		&__logo{
			margin-right: 20px;
			background: #fff;
			text-align: center;
			padding: 8px 10px 5px;
			box-shadow: 0 2px 8px rgba(0,0,0,0.3);
			display: flex;
			flex-direction: column;
			align-items: center;
			border-radius: 5px;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			img{
				max-width: 70px;
				max-height: 35px;
			}
			span{
				font-size: 11px;
				white-space: nowrap;
			}
		}
		&__main{
			position: sticky;
			top: 20px;
			width: 70%;
			padding-right: 40px;
		}
		&__side{
			width: 30%;
		}
		&__empty{
			padding-top: 10px;
			margin-bottom: 20px;
		}
		&__status{
			padding-top: 15px;
		}
		&__footer{
			padding-top: 20px;
			border-top:2px solid $c_main;
			display: flex;
			button{
				margin-right: 20px;
			}
		}
		&__delete{
			transform: translateY(7px);
			svg{
				fill: rgba(0, 0, 0, 0.3);
			}
		}

		&__preview{
			position: fixed;
			z-index: 1;
			right: 15px;
			bottom: 15px;
			background: #fff;
			box-shadow: 0 1px 8px rgba(0,0,0,0.2);
			padding: 10px 15px;
			line-height: 0;
			border-radius: 5px;
			cursor: pointer;
			transition: all 0.3s;
			background: $c_alt;;
			&:hover{
				box-shadow: 0 1px 8px rgba(0,0,0,0.4);
				svg{
					//fill: $c_alt;
				}
			}
			svg{
				width: 20px;
				height: 20px;
				fill: #fff;
			}
		}
		&__save{
			position: fixed;
			z-index: 1;
			right: 80px;
			bottom: 15px;
		}
	}

	.has-lang{
		width: 200px;
	}
</style>
