<template>
	<div class="check__group">
		<label class="check__item" v-for="(item,i) in data" :key="i">
			<input type="radio"
					:name="name"
					v-model="selectItem"
					@change="$emit('input', selectItem)"
					v-validate="rules || ''"
					:value="item.value">
			<i><v-icon name="check"></v-icon></i>
			<span>{{item.name}}</span>
		</label>

		<span v-if="errors.has(name)" class="form__error">{{errors.first(name) }}</span>
	</div>
</template>

<script>
	import 'vue-awesome/icons/check';
	import VIcon from 'vue-awesome/components/Icon';

	export default {
		name: 'form-radio',
		inject: ['$validator'],
		components:{
			VIcon
		},
		props:['value','name','data','rules'],
		data(){
			return {
				selectItem: this.value || ''
			}
		}
	};
</script>

<style scoped lang="scss">

	.check{
		&__group{
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&__item{
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			border-bottom: 1px dotted;
			padding-bottom: 5px;
			border-bottom: none;
			margin-bottom: 0;
			&:first-child{
				i{
					margin-left: 0;
				}
			}
			&:last-child{
				border-bottom: none;
			}
			&:hover{
				i{
					border: 1px solid $c_alt;
				}
				span{
					color: $c_alt;
				}
			}

			i{
				border-radius: 50%;
			}
			input{
				display: none;
				&:checked{
					& ~ {
						i svg{
							opacity: 1;
						}
					}
				}
			}
			i{
				margin-right: 5px;
				margin-left: 15px;
				width: 15px;
				height: 15px;
				display: block;
				flex-shrink: 0;
				border: 1px solid $c_text;

			}
			svg {
				width: 15px;
				height: 15px;
				position: relative;
				bottom: 7px;
				left: 1px;
				opacity: 0;
				transition: all 0.3s;
				@media(max-width: $def){
					bottom: 4px;
				}
			}
			span{
				font-size: 10px;
				text-transform: uppercase;
			}
		}
	}
</style>
