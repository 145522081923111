<template>
	<div class="serv">
		<div class="serv__title">Text in the calculator</div>

		<div class="serv__main">
			<div class="form__item">
				<form-text-edit	v-model="block_one_title"
						:label="`Left block title (${lang.code})`"
						:translate="service.item.translations.block_one_title ?
							service.item.translations.block_one_title[this.DEFAULT_LANG.code] : null"
						:hasTranslate="hasTranslate"
						name="block_one_title"></form-text-edit>
			</div>

			<div class="form__item" v-if="selectedConstructor.includes('level') || selectedConstructor.includes('level_single')">
				<form-text-edit	v-model="current_lvl_title"
						:label="`Current lvl title (${lang.code})`"
						:translate="service.item.translations.current_lvl_title ?
							service.item.translations.current_lvl_title[this.DEFAULT_LANG.code] : null"
						:hasTranslate="hasTranslate"
						name="current_lvl_title"></form-text-edit>
			</div>

			<div class="form__item" v-if="selectedConstructor.includes('division')">
				<form-text-edit	v-model="current_division_title"
						:label="`Current division title (${lang.code})`"
						:translate="service.item.translations.current_division_title ?
							service.item.translations.current_division_title[this.DEFAULT_LANG.code] : null"
						:hasTranslate="hasTranslate"
						name="current_division_title"></form-text-edit>
			</div>

			<div class="form__item" v-if="!selectedConstructor.includes('slider_single') && !selectedConstructor.includes('level_single')">
				<form-text-edit	v-model="block_two_title"
						:label="`Right block title (${lang.code})`"
						:translate="service.item.translations.block_two_title ?
							service.item.translations.block_two_title[this.DEFAULT_LANG.code] : null"
						:hasTranslate="hasTranslate"
						name="block_two_title"></form-text-edit>
			</div>

			<div class="form__item" v-if="selectedConstructor.includes('level') && !selectedConstructor.includes('slider')">
				<form-text-edit	v-model="desired_lvl_title"
						:label="`Desired lvl title (${lang.code})`"
						:translate="service.item.translations.desired_lvl_title ?
							service.item.translations.desired_lvl_title[this.DEFAULT_LANG.code] : null"
						:hasTranslate="hasTranslate"
						name="desired_lvl_title"></form-text-edit>
			</div>

			<div class="form__item" v-if="selectedConstructor.includes('division') && !selectedConstructor.includes('slider')">
				<form-text-edit	v-model="desired_division_title"
						:label="`Desired division title (${lang.code})`"
						:translate="service.item.translations.desired_division_title ?
							service.item.translations.desired_division_title[this.DEFAULT_LANG.code] : null"
						:hasTranslate="hasTranslate"
						name="desired_division_title"></form-text-edit>
			</div>

			<div class="form__item" v-if="selectedConstructor.includes('slider') || selectedConstructor.includes('slider_single')">
				<form-text-edit	v-model="counter_title"
						:label="`Counter title (${lang.code})`"
						:translate="service.item.translations.counter_title ?
							service.item.translations.counter_title[this.DEFAULT_LANG.code] : null"
						:hasTranslate="hasTranslate"
						name="counter_title"></form-text-edit>
			</div>

			<div class="form__item" v-if="selectedConstructor.includes('range')">
				<form-text-edit	v-model="range_title"
						:label="`Range title (${lang.code})`"
						:translate="service.item.translations.range_title ?
							service.item.translations.range_title[this.DEFAULT_LANG.code] : null"
						:hasTranslate="hasTranslate"
						name="range_title"></form-text-edit>
			</div>

			<div class="form__item" v-if="selectedConstructor.includes('range')">
				<form-text-edit	v-model="point_title"
						:label="`Point title (${lang.code})`"
						:translate="service.item.translations.point_title ?
							service.item.translations.point_title[this.DEFAULT_LANG.code] : null"
						:hasTranslate="hasTranslate"
						name="point_title"></form-text-edit>
			</div>
		</div>
	</div>
</template>

<script>
	import FormTextEdit from '@form/FormTextEdit';

	import { createNamespacedHelpers } from 'vuex';
	const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('services');

	export default {
		name: 'service-namespaced',
		components:{
			FormTextEdit
		},
		props: ['lang'],

		computed: {
			...mapState([
				'service', 'CONSTRUCTOR_TYPES'
			]),
			selectedConstructor(){
				return this.CONSTRUCTOR_TYPES[this.service.item.layout_key] || [];
			},
			DEFAULT_LANG(){
				return this.$store.state.DEFAULT_LANG;
			},

			hasTranslate(){
				return 	this.lang.code !== this.DEFAULT_LANG.code
			},

			block_one_title: {
				get () {
					if (!this.service.item.translations.block_one_title) this.setTranslationsProp('block_one_title');
					return this.service.item.translations.block_one_title[this.lang.code]
				},
				set (val) {
					this.SET_SERVICE_TRANSLATE({
						key: 'block_one_title',
						lang: this.lang.code,
						title: val
					})
				}
			},

			current_lvl_title: {
				get () {
					if (!this.service.item.translations.current_lvl_title) this.setTranslationsProp('current_lvl_title');
					return this.service.item.translations.current_lvl_title[this.lang.code]
				},
				set (val) {
					this.SET_SERVICE_TRANSLATE({
						key: 'current_lvl_title',
						lang: this.lang.code,
						title: val
					})
				}
			},

			current_division_title: {
				get () {
					if (!this.service.item.translations.current_division_title) this.setTranslationsProp('current_division_title');
					return this.service.item.translations.current_division_title[this.lang.code]
				},
				set (val) {
					this.SET_SERVICE_TRANSLATE({
						key: 'current_division_title',
						lang: this.lang.code,
						title: val
					})
				}
			},


			block_two_title: {
				get () {
					if (!this.service.item.translations.block_two_title) this.setTranslationsProp('block_two_title');
					return this.service.item.translations.block_two_title[this.lang.code]
				},
				set (val) {
					this.SET_SERVICE_TRANSLATE({
						key: 'block_two_title',
						lang: this.lang.code,
						title: val
					})
				}
			},

			desired_lvl_title: {
				get () {
					if (!this.service.item.translations.desired_lvl_title) this.setTranslationsProp('desired_lvl_title');
					return this.service.item.translations.desired_lvl_title[this.lang.code]
				},
				set (val) {
					this.SET_SERVICE_TRANSLATE({
						key: 'desired_lvl_title',
						lang: this.lang.code,
						title: val
					})
				}
			},

			desired_division_title: {
				get () {
					if (!this.service.item.translations.desired_division_title) this.setTranslationsProp('desired_division_title');
					return this.service.item.translations.desired_division_title[this.lang.code]
				},
				set (val) {
					this.SET_SERVICE_TRANSLATE({
						key: 'desired_division_title',
						lang: this.lang.code,
						title: val
					})
				}
			},

			counter_title: {
				get () {
					if (!this.service.item.translations.counter_title) this.setTranslationsProp('counter_title');
					return this.service.item.translations.counter_title[this.lang.code]
				},
				set (val) {
					this.SET_SERVICE_TRANSLATE({
						key: 'counter_title',
						lang: this.lang.code,
						title: val
					})
				}
			},

			range_title: {
				get () {
					if (!this.service.item.translations.range_title) this.setTranslationsProp('range_title');
					return this.service.item.translations.range_title[this.lang.code]
				},
				set (val) {
					this.SET_SERVICE_TRANSLATE({
						key: 'range_title',
						lang: this.lang.code,
						title: val
					})
				}
			},

			point_title: {
				get () {
					if (!this.service.item.translations.point_title) this.setTranslationsProp('point_title');
					return this.service.item.translations.point_title[this.lang.code]
				},
				set (val) {
					this.SET_SERVICE_TRANSLATE({
						key: 'point_title',
						lang: this.lang.code,
						title: val
					})
				}
			}
		},

		methods: {
			...mapMutations([
				'SET_SERVICE_TRANSLATE',
				'SET_TRANSLATIONS_PROP'
			]),

			setTranslationsProp(key){
				this.SET_TRANSLATIONS_PROP(key)
			}
		}
	};
</script>

<style scoped lang=scss>
	@import '~@css/template/serv.scss';
</style>
