<template>
	<div class="serv">
		<div class="serv__title serv__title--initial">
			<span>Champions</span>
			<div class="serv__plus" @click="onSave(null)">
				<v-icon name="plus"></v-icon>
			</div>
		</div>

		<div class="serv__main" v-if="champions.length">
			<div class="serv__table">
				<table>
					<thead>
					<tr>
						<th></th>
						<th>Title ({{lang.code}})</th>
						<th>Key</th>
						<th></th>
					</tr>
					</thead>

					<tbody>
					<tr v-for="champ in champions" :class="{'has-active': +champ.is_selected}">
						<td>
							<div class="serv__ava" v-if="champ.image">
								<img :src="champ.image" alt="">
							</div>
						</td>
						<td>
							<div class="serv__infoBlock">
								<span>{{champ.name[lang.code] || '-'}}</span>
								<span v-if="hasTranslate">
										<info-modal title="Original translate"
												side="right"
												:text="champ.name[DEFAULT_LANG.code]"></info-modal>
									</span>
							</div>
						</td>
						<td>{{champ.key}}</td>
						<td>
							<div class="serv__tableOptions">
								<div class="serv__tableOption" @click="onSave(champ)">
									<v-icon name="edit"></v-icon>
								</div>

								<div class="serv__tableOption" @click="onDelete(champ)">
									<v-icon name="trash-alt"></v-icon>
								</div>
							</div>
						</td>
					</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
	import 'vue-awesome/icons/plus';
	import 'vue-awesome/icons/trash-alt';
	import 'vue-awesome/icons/edit';
	import VIcon from 'vue-awesome/components/Icon';

	import ChampionsSave from './ChampionsSave';
	import InfoModal from '@components/InfoModal';

	import { createNamespacedHelpers } from 'vuex';
	const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('services');

	export default {
		name: 'champions-index',
		components: {
			VIcon,
			ChampionsSave,
			InfoModal
		},
		props: ['lang'],

		computed: {
			...mapState([
				'service',
			]),
			DEFAULT_LANG(){
				return this.$store.state.DEFAULT_LANG;
			},
			hasTranslate(){
				return 	this.lang.code !== this.DEFAULT_LANG.code
			},
			champions(){
				return this.service.details.champions
			}
		},

		methods: {
			...mapMutations([
				'DELETE_CHAMPION'
			]),

			onSave(option){
				this.$modal.show(ChampionsSave, {option: option, lang:this.lang}, $MODAL_OPTIONS.default);
			},

			onDelete(option){
				if (confirm('Do you want delete champion option?')){
					this.DELETE_CHAMPION(option)
				}
			}
		}
	};
</script>

<style scoped lang=scss>
	@import '~@css/template/serv.scss';

	tr{
		&.has-active{
			td{
				background: $c_alt_light;
			}
		}
	}

	.serv{
		&__ava{
			line-height: 0;
			width: 30px;
			height: 30px;
		}
	}
</style>
