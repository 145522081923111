<template>
	<div class="calc" :class="{'has-sm': !selectedConstructor.includes('division')}">
		<h1 class="calc__title">{{service.item.translations.title[lang.code]}}</h1>

		<div class="calc__range" v-if="selectedConstructor.includes('range')">
			<range-module v-model="range"
					@calculate="onCalculate"
					:lang="lang"></range-module>
		</div>

		<div class="calc__grid">
			<div class="calc__coll">
				<div class="calc__block has-animaLeft has-big"
						:style="{'background-image': `url(${require('@img/review_bg.jpg')}`}">

					<component :is="leftModuleLayout"
							v-model="currentData"
							type="current"
							:points="range[0]"
							@calculate="onCalculate"
							:lang="lang"
							:service="service"></component>

				</div>
			</div>

			<div class="calc__coll">
				<div class="calc__option">
					<div class="calc__item" v-if="champions.length">
						<div class="calc__iTitle">Choose Championship</div>
						<div class="calc__champImg" v-if="championImg">
							<img :src="championImg" alt="">
						</div>
						<form-select v-model="champion"
								class="_m-0"
								:notEmpty="true"
								:options="championOptions"
								option_label="name"
								option_id="key"
								name="champion"></form-select>
					</div>

					<template v-if="serviceParams.length">
						<div class="calc__item" v-for="param in serviceParams">
							<div class="calc__iTitle">{{param.title[lang.code]}}</div>
							<form-radio-group v-model="service_parmas[param.key]"
									:data="getServiceParamsOptions(param.options)"
									:name="`order_options_${param.key}`"></form-radio-group>
						</div>
					</template>

					<div class="calc__item" v-if="orderParams.length">
						<div class="calc__check">
							<div class="calc__iTitle">Select order settings</div>
							<form-check-group v-model="order_params"
									:data="orderParamsOptions"
									name="order_params"></form-check-group>
						</div>
					</div>

					<div class="calc__item" v-if="regions.length">
						<div class="calc__iTitle">Choose region</div>
						<form-select v-model="region"
								class="_m-0"
								:notEmpty="true"
								:options="regionOptions"
								option_label="name"
								option_id="value"
								name="region"></form-select>
					</div>
				</div>

				<div class="calc__block">
					<calc-view-result :endDateLabel="endDateLabel"
							:amount="endTotalPrice"
							@startBoost="onStartBoost"></calc-view-result>
				</div>
			</div>

			<div class="calc__coll">
				<div class="calc__block has-animaRight has-big"
						:style="{'background-image': `url(${require('@img/review_bg.jpg')}`}">

					<component :is="rightModuleLayout"
							v-model="desiredData"
							type="desired"
							:points="range[1]"
							@calculate="onCalculate"
							:lang="lang"
							:service="service"></component>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {map, find, findIndex, forIn} from 'lodash';

	import FormCheckGroup from '@form/FormCheckGroup';
	import FormRadioGroup from '@form/FormRadioGroup';
	import FormSelect from '@form/FormSelect';
	import CalcViewResult from './CalcViewResult';
	import CalcView from './CalcView';

	import CurrentSelectModule from './modules/CurrentSelectModule';
	import DesireSelectModule from './modules/DesireSelectModule';
	import CounterModule from './modules/CounterModule';
	import DummyModule from './modules/DummyModule';
	import RangeInfoModule from './modules/RangeInfoModule';
	import RangeModule from './modules/RangeModule';

	import { createNamespacedHelpers } from 'vuex';
	const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('services');

	export default {
		name: 'calc-index',
		components: {
			FormCheckGroup,
			FormRadioGroup,
			FormSelect,
			CalcViewResult,

			CurrentSelectModule,
			DesireSelectModule,
			CounterModule,
			DummyModule,
			RangeInfoModule,
			RangeModule
		},
		props: ['lang'],
		data(){
			return {
				DAILY_HOUR: 8,
				test: 12,

				order_params: [],
				service_parmas: {},
				region: null,
				champion: null,

				currentData: {
					lvl_key: null,
					division_key: null,
				},
				desiredData: {
					lvl_key: null,
					division_key: null,
				},
				range: [],

				totalPrice: null,
				time: null
			}
		},

		computed:{
			...mapState([
				'service', 'CONSTRUCTOR_TYPES'
			]),
			selectedConstructor(){
				return this.CONSTRUCTOR_TYPES[this.service.item.layout_key]
			},
			orderParams(){
				return this.service.details.order_params;
			},
			serviceParams(){
				return this.service.details.params;
			},
			regions(){
				return 	this.service.details.regions;
			},
			champions(){
				return 	this.service.details.champions;
			},

			championImg(){
				if (!this.champion) return null;

				return 	find(this.champions, ['key', this.champion.key]).image
			},

			championOptions(){
				return map(this.champions, champion => {
					return {
						name: champion.name[this.lang.code],
						key: champion.key
					}
				})
			},

			orderParamsOptions(){
				return map(this.orderParams, option => {
					return {
						name: `${option.title[this.lang.code]} (${Math.sign(+option.rate) !== -1 ? '+' : ''}${+option.rate * 100}%)`,
						value: option.key,
						is_disabled: option.is_disabled
					}
				})
			},

			regionOptions(){
				return map(this.regions, option => {
					let rateLabel = +option.rate ? ` (${Math.sign(+option.rate) !== -1 ? '+' : ''}${+option.rate * 100}%)` : '';
					return {
						name: option.title[this.lang.code] + rateLabel,
						value: option.key
					}
				})
			},


			leftModuleLayout(){
				switch(this.service.item.layout_key){
					case 'slider':
						return 'counter-module';

					case 'range':
						return 'range-info-module';

					default:
						return 'current-select-module';
				}
			},

			rightModuleLayout(){
				switch(this.service.item.layout_key){
					case 'lvl_slider':
					case 'lvl_dv_slider':
						return 'counter-module';

					case 'slider':
					case 'lvl_single':
						return 'dummy-module';

					case 'range':
						return 'range-info-module';

					default:
						return 'desire-select-module';
				}

			},

			priceList(){
				let list = [],
					options = this.service.details.select ? this.service.details.select.options : [];

				switch(this.service.item.layout_key){
					case 'range':
						options.forEach(option => {
							list.push({
								key: option.key,
								start: +option.additional.min,
								end: +option.additional.max,
								price:  +option.additional.price,
								time: +option.additional.time
							})
						});

						break;
					default:
						options.forEach(lvl => {
							let divisions = lvl.inside.options;

							if (divisions.length) {
								divisions.forEach(division => {
									list.push({
										key: `${lvl.key}_${division.key}`,
										price:  +division.additional.price,
										time: +division.additional.time
									})
								})

							} else {
								list.push({
									key: `${lvl.key}`,
									price:  +lvl.additional.price,
									time: +lvl.additional.time
								})
							}
						});
				}


				return list;
			},

			currentKey(){
				return this.currentData.lvl_key + (this.currentData.division_key ? '_' + this.currentData.division_key : '');
			},

			desiredKey(){
				return this.desiredData.lvl_key + (this.desiredData.division_key ? '_' + this.desiredData.division_key : '');
			},


			endTotalPrice(){
				if (!this.totalPrice) return null;

				let factor = 0;

				this.order_params.forEach(key =>{
					factor += +find(this.orderParams,['key', key]).rate;
				});

				forIn(this.service_parmas, (value, key) => {
					let param = find(this.serviceParams, ['key', key]);
					factor += +find(param.options,['value', value]).rate;
				});

				if (this.region){
					let region = find(this.regions, ['key', this.region.value]);
					factor += +region.rate;
				}

				let result = +(this.totalPrice + this.totalPrice * factor).toFixed(2);
				return (result % 10) ? result : result - 0.01;
			},

			endTotalTime(){
				if (!this.time) return null;

				let factor = 0;

				this.order_params.forEach(key =>{
					factor += +find(this.orderParams,['key', key]).date_rate;
				});

				forIn(this.service_parmas, (value, key) => {
					let param = find(this.serviceParams, ['key', key]);
					factor += +find(param.options,['value', value]).date_rate;
				});

				return  this.time + this.time * factor;
			},

			endDateLabel () {
				if (!this.endTotalTime) return '';

				let hour = Math.ceil(this.endTotalTime / 60),
					days = Math.ceil(hour / this.DAILY_HOUR),
					nowDate = new Date();
				let options = {
					month: 'long',
					day: 'numeric',
				};

				nowDate.setDate(nowDate.getDate() + days);

				return nowDate.toLocaleString(this.lang.code, options);
			}
		},

		created(){
			this.setDefaultData();
		},

		methods: {
			onCalculate(){
				switch(this.service.item.layout_key){
					case 'lvl_slider':
					case 'lvl_dv_slider':
						this.calcLvlToSlider();

						break;

					case 'slider':
						this.calcToSlider();

						break;

					case 'range':
						this.calcToRange();

						break;

					case 'lvl_single':
						this.calcLvlSingle();

						break;
					default:
						this.calcLvlToLvl();
				}
			},

			onStartBoost(){
				let result = {
					layout_key: this.service.item.layout_key,

					order_params: this.order_params,
					service_parmas: this.service_parmas,
					region: this.region ? this.region.value : null,
					champions: this.champion ? this.champion.key : null,

					currentData: this.currentData,
					desiredData: this.desiredData,
					range: this.range
				};

				this.$modal.show(CalcView, {result: result}, $MODAL_OPTIONS.default);
			},

			calcLvlSingle(){
				let currentItem = find(this.priceList, ['key', this.currentKey]);

				this.totalPrice = currentItem.price;
				this.time = currentItem.time;
			},

			calcToRange(){
				let startRating = this.range[0],
					endRating = this.range[1];

				let indexStart = findIndex(this.priceList, item => {
						return startRating >= item.start && startRating <= item.end
					}),
					indexEnd = findIndex(this.priceList, item => {
						return endRating >= item.start && endRating <= item.end
					});

				let amount = 0;
				let time = 0;

				if (indexStart === indexEnd){
					amount = (endRating - startRating) * this.priceList[indexStart].price;
					time = (endRating - startRating) * this.priceList[indexStart].time;

				} else {
					for (let i = indexStart; i <= indexEnd; i++){
						let start = this.priceList[i].start,
							end = this.priceList[i].end,
							price = +this.priceList[i].price,
							timePoint = this.priceList[i].time;

						switch(i){
							case indexStart:{
								amount += (end + 1 - startRating) * price;
								time += (end - startRating) * timePoint;
								break;
							}
							case indexEnd: {
								amount += (endRating - start) * price;
								time += (endRating - start) * timePoint;
								break;
							}
							default:{
								amount += (end + 1  - start) * price;
								time += (end - start) * timePoint;
							}
						}
					}
				}

				this.totalPrice = amount;
				this.time = time;
			},

			calcToSlider(){
				let counterData = this.service.details.select ? this.service.details.select.additional : {};

				this.totalPrice = counterData.price ? counterData.price * this.currentData.count : null;
				this.time = counterData.time ?  counterData.time * this.currentData.count : null;
			},

			calcLvlToSlider(){
				let currentItem = find(this.priceList, ['key', this.currentKey]);

				if(!currentItem){
					this.totalPrice = null;
					this.time = null;
					return;
				}

				this.totalPrice = currentItem.price * this.desiredData.count;
				this.time = currentItem.time * this.desiredData.count;
			},

			calcLvlToLvl(){
				let startIndex = findIndex(this.priceList, ['key', this.currentKey]),
					endIndex = findIndex(this.priceList, ['key', this.desiredKey]);

				if(endIndex <= startIndex) {
					this.totalPrice = null;
					this.time = null;
					return false;
				}

				let price = 0;
				let time = 0;

				for (let i = startIndex + 1; i <= endIndex; i++){
					price += this.priceList[i].price;
					time  += this.priceList[i].time;
				}

				this.totalPrice = price;
				this.time = time;
			},

			getServiceParamsOptions(options){
				return map(options, option => {
					return {
						name: option.title[this.lang.code] + (+option.rate ? ` (${Math.sign(+option.rate) !== -1 ? '+' : ''}${+option.rate * 100}%)` : ''),
						value: option.value
					}
				})

			},

			setDefaultData(){
				this.orderParams.forEach(param => {
					if (+param.is_default) this.order_params.push(param.key);
				});

				this.serviceParams.forEach(param => {
					this.$set(this.service_parmas, param.key, null);

					param.options.forEach(option => {
						if (+option.is_default)  this.service_parmas[param.key] = option.value
					})
				});
				
				this.champions.forEach(param => {
					if (+param.is_selected) this.champion = {
						name: param.name[this.lang.code],
						key: param.key
					};
				});

				this.regions.forEach(param => {
					if (+param.is_default) {
						let rateLabel = +param.rate ? ` (${Math.sign(+param.rate) !== -1 ? '+' : ''}${+param.rate * 100}%)` : '';

						this.region = {
							name: param.title[this.lang.code] + rateLabel,
							value: param.key
						}
					}
				})
			}
		}
	};
</script>

<style scoped lang=scss>
	.calc{
		user-select: none;
		position: relative;
		&.has-sm{
			.calc__block{
				&.has-big{
					height: 350px;
				}
			}
		}
		&__title{
			font-weight: bold;
			font-family: $f_alt;
			margin: 0;
			margin-bottom: 30px;
			font-size: 40px;
			text-transform: uppercase;
			text-align: center;
			align-items: center;
			display: flex;
			justify-content: center;
		}
		&__grid{
			display: flex;
			justify-content: center;
			margin-left: -10px;
			margin-right: -10px;
			flex-wrap: wrap;
		}
		&__coll{
			width: 33.333%;
			padding: 0 10px;
			position: relative;
		}
		&__option{
			padding: 0 15px;
		}
		&__block{
			position: sticky;
			top: 10px;
			padding: 20px 15px;
			border: 1px solid #ccc;
			border-radius: 5px;
			background: #fff;
			box-shadow: 0 2px 8px rgba(0,0,0,.2);
			margin-bottom: 20px;
			background-repeat: no-repeat;
			background-position: 50%;
			background-size: cover;
			&.has-big{
				height: 430px;
			}
			&.has-animaLeft{
				animation: bgtoleft 120s linear infinite;
				&:before{
					display: block;
				}
			}
			&.has-animaRight{
				animation: bgtoright 120s linear infinite;
				&:before{
					display: block;
				}
			}
			&:before{
				content: "";
				display: none;
				background: hsla(0,0%,100%,.8);
				border-radius: 5px;
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
			}
		}
		&__item{
			margin-bottom: 20px;
		}
		&__iTitle{
			font-family: $f_alt;
			text-transform: uppercase;
			text-align: center;
			font-weight: bold;
			font-size: 13px;
			margin-bottom: 10px;
		}
		&__range{
			margin-bottom: 20px;
		}

		&__champImg{
			margin: 0 auto 0;
			text-align: center;
			line-height: 0;
			max-width: 75px;
		}
	}


	@keyframes bgtoleft {
		0%{
			background-position: left center;
		}
		50%{
			background-position: right center;
		}
		100%{
			background-position: left center;
		}
	}
	@keyframes bgtoright {
		0%{
			background-position: right center;
		}
		50%{
			background-position: left center;
		}
		100%{
			background-position: right center;
		}
	}
</style>
