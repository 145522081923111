<template>
	<div class="check__group">
		<label class="check__item" v-for="(item,i) in data" :key="i" :class="{'has-checked': +item.is_disabled}">
			<input type="checkbox"
					:name="name"
					v-model="checkList"
					@change="$emit('input', checkList)"
					v-validate="rules || ''"
					:value="item.value">
			<i><v-icon name="check"></v-icon></i>
			<span>{{item.name}}</span>
		</label>

		<span v-if="errors.has(name)" class="form__error">{{errors.first(name) }}</span>
	</div>
</template>

<script>
	import 'vue-awesome/icons/check';
	import VIcon from 'vue-awesome/components/Icon';

	export default {
		name: 'form-checkGroup',
		inject: ['$validator'],
		components:{
			VIcon
		},
		props:['value','name','data','rules'],
		data(){
			return {
				checkList: this.value || []
			}
		}
	};
</script>

<style scoped lang="scss">

	.check{
		&__group{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
		}
		&__item{
			cursor: pointer;
			width: 100%;
			border-bottom: 1px dotted $c_border;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			border-bottom: 1px dotted;
			padding-bottom: 10px;
			margin-bottom: 10px;
			&:last-child{
				border-bottom: none;
			}
			&:hover{
				i{
					border: 1px solid $c_alt;
				}
				span{
					color: $c_alt;
				}
			}
			&.has-checked{
				pointer-events: none;
				i{
					background: #eee;
				}
			}
			input{
				display: none;
				&:checked{
					& ~ {
						i svg{
							opacity: 1;
						}
					}
				}
			}
			i{
				margin-right: 10px;
				width: 15px;
				height: 15px;
				display: block;
				border: 1px solid $c_text;
				flex-shrink: 0;
			}
			svg {
				width: 15px;
				height: 15px;
				position: relative;
				bottom: 4px;
				left: 1px;
				opacity: 0;
				transition: all 0.3s;
				transform: translateY(-1px);
			}
			span{
				font-size: 10px;
				text-transform: uppercase;
			}
		}
	}
</style>
