<template>
	<div class="module">
		<div class="module__title">{{type === 'current' ? translation.block_one_title[lang.code] :
														  translation.block_two_title[lang.code]}}</div>
		<div class="module__counter">
			<div class="module__img">
				<img v-if="img" :src="img" alt="">
				<img v-else :src="require('@img/logo.png')" alt="">
			</div>
			<div class="module__info">{{points}} {{translation.point_title[lang.code]}}</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'range-info-module',
		props: ['service', 'lang', 'type', 'points'],

		computed:{
			translation(){
				return this.service.item.translations
			},
			options(){
				return this.service.details.select.options;
			},

			img(){
				let img = null;

				this.options.forEach(option => {
					if (this.points >= +option.additional.min && this.points <= +option.additional.max){
						img = option.additional.image
					}
				});

				return img;
			}
		}
	};
</script>

<style scoped lang=scss>
	.module{
		position: relative;
		&__title{
			font-family: $f_alt;
			text-transform: uppercase;
			text-align: center;
			font-weight: bold;
			font-size: 18px;
			position: relative;
			z-index: 1;
			line-height: 1.3em;
			height: 2.6em;
			overflow: hidden;
			margin-bottom: 0;
		}
		&__img{
			max-width: 120px;
			margin: 0 auto;
			height: 180px;
			display: flex;
			align-items: center;
			justify-content: center;
			line-height: 0;
			position: relative;
			z-index: 1;
		}
		&__info{
			text-align: center;
			text-transform: uppercase;
			font-family: $f_alt;
			font-weight: bold;
			font-size: 22px;
			position: relative;
			z-index: 1;
		}
	}
</style>
