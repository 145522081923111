<template>
	<div class="result">
		<div class="calc__mainError" v-if="!amount">
			<div class="calc__mainError-wrap">
				<span>Error! The desired level is lower than the current: ( <br> or not selected service option</span>
			</div>
		</div>

		<div class="calc__totalBlock" v-else>
			<div class="calc__totalHead">
				<div class="calc__totalTitle">Total price</div>
				<div class="calc__price">
					<div class="calc__price-item">
						<!--<div class="calc__total-old">-->
							<!--<span>{{amountOld.toFixed(2) | numberNormalize}}</span>-->
						<!--</div>-->
						<div class="calc__total-new">
							<span>{{amount.toFixed(2) | numberNormalize}}</span> {{currencyMain.label}}
						</div>
					</div>
				</div>

				<div class="date">
					<div class="date__cnt">
						<div class="date__title">Date of completion <span>to </span></div>
						<div class="date__val">{{endDateLabel}}</div>
						<div class="inf">
							<div class="inf__head">
								<v-icon name="info-circle"></v-icon>
							</div>
							<div class="inf__cnt">This is the extreme date of completion of the order. As a rule, booster copes much earlier than the specified period.</div>
						</div>
					</div>
				</div>
			</div>

			<div class="_flex _justify-center">
				<button class="btn" @click="$emit('startBoost')"><span>Order</span></button>
			</div>
		</div>
	</div>
</template>

<script>
	import 'vue-awesome/icons/info-circle';
	import VIcon from 'vue-awesome/components/Icon';

	export default {
		name: 'calc-view-result',
		components:{
			VIcon
		},
		props: ['amount', 'endDateLabel'],
		data () {
			return {
				discount: 0.15,
			};
		},

		computed: {
			currencyMain () {
				//TODO change currencies
				return {
					label: '$'
				}
				// return $store.state.currencyMain;
			},
			currencyAlternative () {
				//TODO change currencies
				return {
					label: '₽'
				}
				// return $store.state.currencyAlternative;
			},

			amountOld () {
				return +(this.amount + this.amount * this.discount).toFixed(2);
			},
			amountAlternative () {
				return +(this.amount * this.currencyAlternative.rate).toFixed(2);
			},
			amountAlternativeOld () {
				return +(this.amountAlternative + this.amountAlternative * this.discount).toFixed(2);
			}

		}

	};
</script>

<style scoped lang=scss>
	.inf{
		position: relative;
		&__head{
			padding: 3px;
			height: 23px;
			width: 22px;
			cursor: pointer;
			&:hover{
				+ .inf__cnt{
					opacity: 1;
					bottom: 30px;
					visibility: visible;
				}
				svg{
					fill: $c_alt;
				}
			}
		}
		&__cnt{
			position: absolute;
			z-index: 1;
			visibility: hidden;
			opacity: 0;
			transition: all 0.3s;
			right: -20px;
			bottom: 40px;
			width: 240px;
			padding: 15px;
			font-size: 14px;
			color: #fff;
			background: $c_main;
			border-radius: 8px;
			font-family: $f_alt;
			line-height: 1.3;
			box-shadow: 0 0 8px rgba(0,0,0,0.3);
			text-transform: none;
			@media(max-width: $md){
				font-size: 13px;
			}
			&:before {
				width: 14px;
				height: 14px;
				content: '';
				background: $c_main;
				transform: rotate(45deg);
				position: absolute;
				right: 24px;
				bottom: -4px;
			}
		}
	}

	.date {
		text-align: center;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		justify-content: center;
		.inf{
			position: absolute;
			right: -23px;
			top: -5px;
		}
		&__cnt{
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&__title {
			font-family: $f_alt;
			margin-right: 5px;
			font-size: 9px;
			span {
				font-weight: bold;
				color: $c_alt;
			}
		}
		&__val {
			font-family: $f_alt;
			font-weight: bold;
			font-size: 12px;
		}
	}

	.calc {
		&__mainError {
			text-transform: uppercase;
			font-size: 11px;
			color: $c_text;
			border-radius: 5px;
			text-align: left;
			font-family: $f_alt;
			&-wrap {
				display: flex;
				align-items: center;
				justify-content: center;
				color: $c_alt;
			}
			.loader {
				display: inline-block;
				margin-right: 15px;
				svg {
					fill: $c_text;
				}
			}
			span {
				display: inline-block;
				font-weight: bold;
				font-size: 14px;
				text-align: center;
			}
		}
		&__totalHead {
			margin-bottom: 10px;
			.calc__title {
				margin-bottom: 0px;
			}
		}

		&__price {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 10px;
			&-item {
				width: 45%;
				text-align: center;
			}
			&-dec {
				width: 10%;
			}
		}

		&__total {
			display: flex;
			justify-content: center;
			align-items: center;
			&-old {
				color: #3131317a;
				font-size: 14px;
				line-height: 1.1;
				span {
					position: relative;
					padding: 0 5px;
					&:before {
						content: '';
						display: block;
						position: absolute;
						left: 0;
						top: 50%;
						height: 1px;
						width: 100%;
						background: #3131317a;
					}
				}
			}
			&-new {
				line-height: 1.1;
				font-size: 20px;
				font-weight: bold;
				white-space: nowrap;
			}
			&-or {
				font-family: $f_alt;
				font-size: 18px;
				padding: 0 20px;
			}
		}
		&__totalTitle{
			font-family: $f_alt;
			text-transform: uppercase;
			text-align: center;
			font-weight: bold;
			font-size: 20px;
			margin-bottom: 5px;
			position: relative;
			z-index: 1;
		}
	}


</style>
