<template>
	<div class="vmodal vmodal--ease">
		<button class="vmodal__close" @click="$emit('close')">
			<v-icon name="times"></v-icon>
		</button>

		<div class="vmodal__container">
			<div class="vmodal__main">
				<div class="form">
					<div class="form__head">
						<div class="form__icon">
							<v-icon name="cubes"></v-icon>
						</div>
						<div class="form__title">{{hasEdit ? 'Edit' : 'Create'}} division option</div>
					</div>

					<div class="grid">
						<div class="grid__coll grid__coll--6">
							<div class="form__item">
								<form-input v-model="text"
										:label="`Title (${lang.code})`"
										:translate="option ? option.text[this.DEFAULT_LANG.code] : null"
										:hasTranslate="hasTranslate"
										rules="required"
										@enter="onSave"
										name="text"></form-input>
							</div>
						</div>

						<div class="grid__coll grid__coll--6">
							<div class="form__item">
								<form-input v-model="key"
										:class="{'has-disabled':  hasEdit}"
										label="Key"
										rules="required"
										@enter="onSave"
										name="key"></form-input>
							</div>
						</div>
					</div>

					<div class="grid">
						<div class="grid__coll grid__coll--6">
							<div class="form__item">
								<form-img v-model="image"
										label="Add icon"
										name="icon"></form-img>
							</div>
						</div>

						<div class="grid__coll grid__coll--6">
							<div class="form__item">
								<form-input v-model="price"
										type="number"
										label="Price, $"
										rules="required"
										@enter="onSave"
										name="price"></form-input>
							</div>

							<div class="form__item">
								<form-input v-model="time"
										type="number"
										label="Time, m"
										rules="required"
										@enter="onSave"
										translate="При установке времени исполнения, следует учитывать что система рассчитывает дедлай с учотом 8-ми часового робочого дня (1 роб. День = 480 минут)."
										:hasTranslate="true"
										name="time"></form-input>
							</div>

							<div class="grid">
								<div class="grid__coll grid__coll--6">
									<div class="form__item">
										<form-checkbox v-model="is_current_default"
												class="_m-0 has-sm"
												:class="{'has-disabled': is_desired_default}"
												label="Current default"
												name="is_current_default"></form-checkbox>
									</div>
								</div>
								<div class="grid__coll grid__coll--6" v-if="!selectedConstructor.includes('slider')">
									<div class="form__item">
										<form-checkbox v-model="is_desired_default"
												class="_m-0 has-sm"
												:class="{'has-disabled': is_current_default}"
												label="Desired default"
												name="is_desired_default"></form-checkbox>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="form__footer">
						<button class="btn"
								@click="onSave"><span>{{hasEdit ? 'Edit' : 'Create'}}</span></button>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {assign} from 'lodash';

	import 'vue-awesome/icons/times';
	import 'vue-awesome/icons/cubes';
	import VIcon from 'vue-awesome/components/Icon';

	import FormInput from '@form/FormInput';
	import FormImg from '@form/FormImg';
	import FormCheckbox from '@form/FormCheckbox';
	import InfoModal from '@components/InfoModal';

	import { createNamespacedHelpers } from 'vuex';
	const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('services');

	export default {
		name: 'service-data-division-save',
		components: {
			VIcon,
			FormInput,
			FormImg,
			FormCheckbox,
			InfoModal
		},
		props:['option', 'lang', 'saveOption'],
		data(){
			return {
				text: null,
				key: null,
				image: null,
				price: null,
				time: null,
				is_current_default: false,
				is_desired_default: false,
			}
		},

		computed:{
			...mapState([
				'service', 'CONSTRUCTOR_TYPES'
			]),
			selectedConstructor(){
				return this.CONSTRUCTOR_TYPES[this.service.item.layout_key]
			},

			hasEdit(){
				return !!this.option;
			},

			DEFAULT_LANG(){
				return this.$store.state.DEFAULT_LANG;
			},

			hasTranslate(){
				return 	(this.lang.code !== this.DEFAULT_LANG.code) && this.hasEdit
			},
		},

		created(){
			if(this.hasEdit) this.buildData();
		},

		methods: {
			onSave(){
				this.$validator.validate().then(result => {
					if (result) {
						this.saveOption({
							id: this.hasEdit ? this.option.id : null,
							text: assign(this.option ? this.option.text : {}, {
								[this.lang.code]: this.text
							}),
							key: this.key,
							position: 0,
							is_selected: false,
							additional:{
								image: this.image,
								price: this.price,
								time: this.time,
								is_current_default: !!this.is_current_default,
								is_desired_default: !!this.is_desired_default,
							}
						});

						this.$emit('close');
					}
				});
			},

			buildData(){
				let option = this.option;

				this.text = option.text[this.lang.code];
				this.key = option.key;
				this.image = option.additional.image;
				this.price = option.additional.price;
				this.time = option.additional.time;
				this.is_current_default = +option.additional.is_current_default;
				this.is_desired_default = +option.additional.is_desired_default;
			}
		}
	};
</script>

<style scoped lang=scss>

</style>
