<template>
	<div class="module">
		<div class="module__title">{{type === 'current' ? translation.block_one_title[lang.code] : translation.block_two_title[lang.code]}}</div>
		<div class="module__counter">
			<div class="counter">
				<div class="counter__num">
					<input type="number" v-model="count"
							@focus="$event.target.select()"
							@change="onChangeNum($event)"></div>

				<div class="counter__title">{{translation.counter_title[lang.code]}}</div>

				<div class="counter__slider">
					<vue-slider v-model="count"
							tooltip="none"
							@change="onChangeCounter()"
							:min="+counter.counter_min || 1"
							:max="+counter.counter_max || 10">
					</vue-slider>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import vueSlider from 'vue-slider-component';
	import 'vue-slider-component/theme/antd.css'

	export default {
		name: 'counter-module',
		components:{
			vueSlider
		},
		props: ['service', 'lang', 'type'],
		data(){
			return {
				count: this.service.details.select ? (this.service.details.select.additional.counter_selected || 1) : 1
			}
		},

		computed: {
			translation () {
				return this.service.item.translations
			},
			counter(){
				return this.service.details.select ? this.service.details.select.additional : {};
			}
		},

		created(){
			this.onChangeCounter();
		},

		methods: {
			onChangeCounter(){
				this.$emit('input',{
					count: this.count,
				});
				this.$emit('calculate');
			},

			onChangeNum(e){
				let num = +(e.target.value.replace(/\D/g, ''));

				if (num > this.counter.counter_max) num = this.counter.counter_max;
				if (!num) num = this.counter.counter_min;

				this.count = num;
				this.onChangeCounter();
			}
		}
	};
</script>

<style scoped lang=scss>
	.module {
		position: relative;
		&__title {
			font-family: $f_alt;
			text-transform: uppercase;
			text-align: center;
			font-weight: bold;
			font-size: 18px;
			position: relative;
			z-index: 1;
			line-height: 1.3em;
			height: 2.6em;
			overflow: hidden;
			margin-bottom: 0;
		}
		&__counter{
			height: 280px;
			flex-grow: 1;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			position: relative;
			z-index: 1;
			justify-content: center;
			align-items: center;
			padding-bottom: 0!important;
		}
	}

	.counter{
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
		&__num{
			width: 100%;
			input{
				border: none;
				width: 100%;
				display: block;
				background: none;
				font-size: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
				font-weight: bold;
				font-family: $f_alt;
			}
		}
		&__title{
			text-align: center;
			font-weight: bold;
			font-family: $f_alt;
			text-transform: uppercase;
			font-size: 18px;
			margin-bottom: 15px;
		}
		&__slider{
			display: block;
			width: 100%;
		}
	}
</style>
