<template>
	<div class="vmodal vmodal--ease">
		<button class="vmodal__close" @click="$emit('close')">
			<v-icon name="times"></v-icon>
		</button>

		<div class="vmodal__container">
			<div class="vmodal__main">
				<div class="form">
					<div class="form__head">
						<div class="form__icon">
							<v-icon name="cubes"></v-icon>
						</div>
						<div class="form__title">Result</div>
					</div>

					<div class="view">
						<pre>{{viewData}}</pre>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import 'vue-awesome/icons/times';
	import 'vue-awesome/icons/cubes';
	import VIcon from 'vue-awesome/components/Icon';

	export default {
		name: 'calc-view',
		components:{
			VIcon
		},
		props: ['result'],

		computed:{
			viewData(){
				return JSON.stringify(this.result, null, 4);
			}
		}
	};
</script>

<style scoped lang=scss>

</style>
