<template>
	<div class="vmodal vmodal--ease">
		<button class="vmodal__close" @click="$emit('close')">
			<v-icon name="times"></v-icon>
		</button>

		<div class="vmodal__container">
			<div class="vmodal__main">
				<div class="form">
					<div class="form__head">
						<div class="form__icon">
							<v-icon name="cubes"></v-icon>
						</div>
						<div class="form__title">Edit counter option</div>
					</div>

					<div class="grid">
						<div class="grid__coll grid__coll--4">
							<div class="form__item">
								<form-input v-model="counter_min"
										type="number"
										label="Min position"
										rules="required"
										@enter="onSave"
										name="counter_min"></form-input>
							</div>
						</div>
						<div class="grid__coll grid__coll--4">
							<div class="form__item">
								<form-input v-model="counter_max"
										type="number"
										label="Max position"
										rules="required"
										@enter="onSave"
										name="counter_max"></form-input>
							</div>
						</div>
						<div class="grid__coll grid__coll--4">
							<div class="form__item" v-if="!selectedConstructor.includes('range')">
								<form-input v-model="counter_selected"
										type="number"
										label="Selected position"
										rules="required"
										@enter="onSave"
										name="counter_selected"></form-input>
							</div>

							<div class="form__item" v-if="selectedConstructor.includes('range')">
								<form-input v-model="limit"
										type="number"
										label="Limit step"
										rules="required"
										@enter="onSave"
										name="limit"></form-input>
							</div>
						</div>
					</div>

					<div class="grid" v-if="selectedConstructor.includes('slider_single')">
						<div class="grid__coll grid__coll--6">
							<div class="form__item">
								<form-input v-model="price"
										type="number"
										label="Price"
										rules="required"
										@enter="onSave"
										name="price"></form-input>
							</div>
						</div>

						<div class="grid__coll grid__coll--6">
							<div class="form__item">
								<form-input v-model="time"
										type="number"
										label="Time"
										rules="required"
										@enter="onSave"
										translate="При установке времени исполнения, следует учитывать что система рассчитывает дедлай с учотом 8-ми часового робочого дня (1 роб. День = 480 минут)."
										:hasTranslate="true"
										name="time"></form-input>
							</div>
						</div>
					</div>

					<div class="grid" v-if="selectedConstructor.includes('range')">
						<div class="grid__coll grid__coll--6">
							<div class="form__item">
								<form-input v-model="selected_min"
										type="number"
										label="Selected min position"
										rules="required"
										@enter="onSave"
										name="selected_min"></form-input>
							</div>
						</div>

						<div class="grid__coll grid__coll--6">
							<div class="form__item">
								<form-input v-model="selected_max"
										type="number"
										label="Selected max position"
										rules="required"
										@enter="onSave"
										name="selected_max"></form-input>
							</div>
						</div>
					</div>


					<div class="form__footer">
						<button class="btn"
								@click="onSave"><span>Edit</span></button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import 'vue-awesome/icons/times';
	import 'vue-awesome/icons/cubes';
	import VIcon from 'vue-awesome/components/Icon';

	import FormInput from '@form/FormInput';

	import { createNamespacedHelpers } from 'vuex';
	const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('services');

	export default {
		name: 'service-data-counter-save',
		components: {
			VIcon,
			FormInput
		},
		props: ['option'],
		data(){
			return{
				counter_min: this.option.counter_min,
				counter_max: this.option.counter_max,
				counter_selected: this.option.counter_selected,
				price: this.option.price,
				time: this.option.time,

				limit: this.option.limit,
				selected_min: this.option.selected_min,
				selected_max: this.option.selected_max
			}
		},

		computed:{
			...mapState([
				'service', 'CONSTRUCTOR_TYPES'
			]),
			selectedConstructor(){
				return this.CONSTRUCTOR_TYPES[this.service.item.layout_key] || [];
			},
		},

		methods: {
			...mapMutations([
				'SAVE_DATA_COUNTER'
			]),

			onSave(){
				this.$validator.validate().then(async result => {
					if (result) {
						this.SAVE_DATA_COUNTER({
							counter_min: this.counter_min,
							counter_max: this.counter_max,
							counter_selected: this.counter_selected,
							price: this.price,
							time: this.time,
							limit: this.limit,
							selected_min: this.selected_min,
							selected_max: this.selected_max,
						});
						this.$emit('close');
					}
				});
			}
		}
	};
</script>

<style scoped lang=scss>

</style>
