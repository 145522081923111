<template>
	<div class="serv">
		<div class="serv__title">SEO data</div>

		<div class="serv__main">
			<div class="form__item">
				<form-text-edit	v-model="seo_title"
						:label="`Seo page title (${lang.code})`"
						:translate="service.item.translations.seo_title ?
							service.item.translations.seo_title[this.DEFAULT_LANG.code] : null"
						:hasTranslate="hasTranslate"
						name="seo_title"></form-text-edit>
			</div>

			<div class="form__item">
				<form-textarea	v-model="seo_description"
						:label="`Seo page description (${lang.code})`"
						:translate="service.item.translations.seo_description ?
							service.item.translations.seo_description[this.DEFAULT_LANG.code] : null"
						:hasTranslate="hasTranslate"
						name="seo_description"></form-textarea>
			</div>
		</div>
	</div>
</template>

<script>
	import FormTextEdit from '@form/FormTextEdit';
	import FormTextarea from '@form/FormTextarea';

	import { createNamespacedHelpers } from 'vuex';
	const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('services');

	export default {
		name: 'sevice-seo',
		components:{
			FormTextEdit,
			FormTextarea
		},
		props: ['lang'],

		computed: {
			...mapState([
				'service'
			]),
			DEFAULT_LANG(){
				return this.$store.state.DEFAULT_LANG;
			},

			hasTranslate(){
				return 	this.lang.code !== this.DEFAULT_LANG.code
			},

			seo_title: {
				get () {
					if (!this.service.item.translations.seo_title) this.setTranslationsProp('seo_title');
					return this.service.item.translations.seo_title[this.lang.code]
				},
				set (val) {
					this.SET_SERVICE_TRANSLATE({
						key: 'seo_title',
						lang: this.lang.code,
						title: val
					})
				}
			},

			seo_description: {
				get () {
					if (!this.service.item.translations.seo_description) this.setTranslationsProp('seo_description');
					return this.service.item.translations.seo_description[this.lang.code]
				},
				set (val) {
					this.SET_SERVICE_TRANSLATE({
						key: 'seo_description',
						lang: this.lang.code,
						title: val
					})
				}
			},
		},

		methods: {
			...mapMutations([
				'SET_SERVICE_TRANSLATE',
				'SET_TRANSLATIONS_PROP'
			]),

			setTranslationsProp(key){
				this.SET_TRANSLATIONS_PROP(key)
			}
		}
	};
</script>

<style scoped lang=scss>
	@import '~@css/template/serv.scss';
</style>
