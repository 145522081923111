<template>
	<div class="module">
		<div class="module__title">{{translation.block_one_title ? translation.block_one_title[lang.code] : ''}}</div>
		<div class="module__img">
			<img v-if="selectedDivision && selectedDivision.additional.image" :src="selectedDivision.additional.image" alt="">
			<img v-else-if="selectedLvl && selectedLvl.additional.image" :src="selectedLvl.additional.image" alt="">
			<img v-else class="module__logo" :src="require('@img/logo.png')" alt="">
		</div>
		<div class="module__group">
			<div class="module__item" v-if="lvlList.length">
				<form-select v-model="lvl"
						class="_m-0 has-calc"
						:notEmpty="true"
						:options="lvlListOptions"
						:label="translation.current_lvl_title ? translation.current_lvl_title[lang.code] : ''"
						option_label="name"
						option_id="key"
						@input="onChangeLvl"
						name="lvl"></form-select>
			</div>
			<div class="module__item" v-if="divisionList.length">
				<form-select v-model="division"
						class="_m-0 has-calc"
						:notEmpty="true"
						:options="divisionListOptions"
						:label="translation.current_division_title ? translation.current_division_title[lang.code] : ''"
						option_label="name"
						option_id="key"
						@input="onChangeDivision"
						name="division"></form-select>
			</div>
		</div>
	</div>
</template>

<script>
	import {map, find} from 'lodash';

	import FormSelect from '@form/FormSelect';

	export default {
		name: 'current-select-module',
		components: {
			FormSelect
		},
		props: ['service', 'lang'],
		data(){
			return{
				lvl: null,
				division: null
			}
		},

		computed:{
			selectedConstructor(){
				return this.CONSTRUCTOR_TYPES[this.service.item.layout_key]
			},
			translation(){
				return this.service.item.translations
			},
			lvlList(){
				return this.service.details.select ? this.service.details.select.options : [];
			},

			lvlListOptions(){
				return map(this.lvlList, option => {
					return {
						name: option.text[this.lang.code],
						key: option.key
					}
				})
			},

			divisionList(){
				return this.selectedLvl ? this.selectedLvl.inside.options : [];
			},

			divisionListOptions(){
				return map(this.divisionList, option => {
					return {
						name: option.text[this.lang.code],
						key: option.key
					}
				})
			},

			selectedLvl(){
				if (!this.lvl) return null;
				return find(this.lvlList, ['key', this.lvl.key])
			},

			selectedDivision(){
				if (!this.division) return null;
				return find(this.divisionList, ['key', this.division.key])
			}
		},

		created(){
			this.setDefaultData();
			this.emitData();
			this.$emit('calculate');
		},

		methods: {
			onChangeLvl(){
				if (this.divisionList.length){
					this.division = {
						name: this.divisionList[0].text[this.lang.code],
						key: this.divisionList[0].key
					};

				} else {
					this.division = null;
				}

				this.emitData();
				this.$emit('calculate');
			},

			onChangeDivision(){
				this.emitData();
				this.$emit('calculate');
			},

			emitData(){
				this.$emit('input',{
					lvl_key: this.lvl ? this.lvl.key : null,
					division_key: this.division ? this.division.key : null,
				})
			},

			setDefaultData(){
				if (!this.lvlList.length) return;

				let currentLvl = find(this.lvlList, lvl => {
					return +lvl.additional.is_current_default
				})
				let currentDivision = null;

				if (currentLvl){
					this.lvl = {
						name: currentLvl.text[this.lang.code],
						key: currentLvl.key
					};

					currentDivision = find(currentLvl.inside.options, division => {
						return +division.additional.is_current_default
					});
				}

				if (currentDivision){
					this.division = currentDivision ? {
						name: currentDivision.text[this.lang.code],
						key: currentDivision.key
					} : null;
				}
			}
		}
	};
</script>

<style scoped lang=scss>
	.module{
		position: relative;
		&__title{
			font-family: $f_alt;
			text-transform: uppercase;
			text-align: center;
			font-weight: bold;
			font-size: 18px;
			position: relative;
			z-index: 1;
			line-height: 1.3em;
			height: 2.6em;
			overflow: hidden;
			margin-bottom: 0;
		}
		&__img{
			max-width: 120px;
			margin: 0 auto;
			height: 180px;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			align-items: center;
			justify-content: center;
			line-height: 0;
			position: relative;
			z-index: 1;
		}
		&__group{
			position: relative;
			min-height: 160px;
		}
		&__item{
			padding: 15px 0;
		}
		&__logo{
			max-width: 160%;
		}
	}
</style>
