<template>
	<div class="data">
		<div class="data__options"  v-if="selectedConstructor.includes('level') || selectedConstructor.includes('level_single')">
			<div class="serv__title serv__title--initial">
				<span>Level option list</span>
			</div>

			<div class="data__table"  v-if="selectData && selectData.options.length">
				<div class="table"  v-for="option in selectData.options">
					<div class="table__head">
						<div class="table__cnt">
							<div class="table__td table__td--icon" v-if="option.inside && !option.inside.options.length"></div>
							<div class="table__td table__td--key">Key</div>
							<div class="table__td table__td--name">Level name ({{lang.code}})</div>
							<template v-if="option.inside && !option.inside.options.length">
								<div class="table__td table__td--price">Price, $</div>
								<div class="table__td table__td--time">Time, m(h)</div>
							</template>
						</div>

						<div class="table__opt">
							<div class="table__td table__td--nav"></div>
						</div>
					</div>

					<div class="table__body">
						<div class="table__tr">
							<div class="table__lvl"
									:class="{'has-start': +option.additional.is_current_default,
											 'has-end': +option.additional.is_desired_default}">
								<div class="table__cnt">
									<div class="table__td table__td--icon" v-if="option.inside && !option.inside.options.length">
										<div class="data__ava">
											<img :src="option.additional.image" alt="">
										</div>
									</div>

									<div class="table__td table__td--key">{{option.key}}</div>

									<div class="table__td table__td--name">
										<div class="serv__infoBlock">
											<span>{{option.text[lang.code] || '-'}}</span>
											<span v-if="hasTranslate">
												<info-modal title="Original translate"
														side="right"
														:text="option.text ? option.text[DEFAULT_LANG.code] : null"></info-modal>
											</span>
										</div>
									</div>

									<template v-if="option.inside && !option.inside.options.length">
										<div class="table__td table__td--price"><b>{{option.additional.price | numberNormalize}}</b></div>

										<div class="table__td table__td--time">
											<template v-if="option.additional.time">
												{{option.additional.time | numberNormalize}}({{(option.additional.time/60).toFixed(1) | numberNormalize}})
											</template>
										</div>
									</template>
								</div>

								<div class="table__opt">
									<div class="table__td table__td--nav">
										<div class="serv__tableOptions">
											<div class="serv__tableOption" @click="onSave(option)">
												<v-icon name="edit"></v-icon>
											</div>

											<div class="serv__tableOption" @click="onDelete(option)">
												<v-icon name="trash-alt"></v-icon>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="table__dv" v-if="option.inside && option.inside.options.length">
								<div class="table__dvTitle">
									<span>Divisions:</span>
								</div>
								<div class="serv__table">
									<table>
										<thead>
										<tr>
											<th></th>
											<th>Key</th>
											<th>Division name ({{lang.code}})</th>
											<th>Price, $</th>
											<th>Time, m(h)</th>
										</tr>
										</thead>

										<tbody>
										<tr v-for="inside in option.inside.options"
												:class="{'has-start': +inside.additional.is_current_default,
														 'has-end': +inside.additional.is_desired_default}">
											<td>
												<div class="data__ava">
													<img :src="inside.additional.image" alt="">
												</div>
											</td>
											<td>{{inside.key}}</td>
											<td>
												<div class="serv__infoBlock">
													<span>{{inside.text[lang.code] || '-'}}</span>
													<span v-if="hasTranslate">
													<info-modal title="Original translate"
															side="right"
															:text="inside.text[DEFAULT_LANG.code]"></info-modal>
												</span>
												</div>
											</td>
											<td>
												<b>{{inside.additional.price | numberNormalize}}</b>
											</td>
											<td>
												{{inside.additional.time | numberNormalize}}({{(inside.additional.time/60).toFixed(1) | numberNormalize}})
											</td>
										</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="serv__add" @click="onSave(null)">
				<v-icon name="plus"></v-icon>
			</div>
		</div>

		<div class="data__options" v-if="selectedConstructor.includes('range')">
			<div class="serv__title  serv__title--initial">
				<span>Range option list</span>
			</div>

			<div class="data__table"  v-if="selectData && selectData.options.length">
				<div class="table"  v-for="option in selectData.options">
					<div class="table__head">
						<div class="table__cnt">
							<div class="table__td table__td--icon"></div>
							<div class="table__td table__td--key">Key</div>
							<div class="table__td table__td--range">[Start ... End] range</div>
							<div class="table__td table__td--price">Price, $</div>
							<div class="table__td table__td--time">Time, m</div>
						</div>

						<div class="table__opt">
							<div class="table__td table__td--nav"></div>
						</div>
					</div>

					<div class="table__body">
						<div class="table__tr">
							<div class="table__lvl">
								<div class="table__cnt">
									<div class="table__td table__td--icon">
										<div class="data__ava">
											<img :src="option.additional.image" alt="">
										</div>
									</div>
									<div class="table__td table__td--key">{{option.key}}</div>
									<div class="table__td table__td--range">[<span>{{option.additional.min}}</span> ... <span>{{option.additional.max}}</span>]</div>
									<div class="table__td table__td--price"><b>{{option.additional.price | numberNormalize}}</b></div>
									<div class="table__td table__td--time">
										<template v-if="option.additional.time">
											{{option.additional.time | numberNormalize}}
										</template>
									</div>
								</div>

								<div class="table__opt">
									<div class="table__td table__td--nav">
										<div class="serv__tableOptions">
											<div class="serv__tableOption" @click="onSaveRange(option)">
												<v-icon name="edit"></v-icon>
											</div>

											<div class="serv__tableOption" @click="onDelete(option)">
												<v-icon name="trash-alt"></v-icon>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="serv__add" @click="onSaveRange(null)">
				<v-icon name="plus"></v-icon>
			</div>
		</div>

		<div class="data__options data__options--counter" v-if="selectedConstructor.includes('slider') ||
															selectedConstructor.includes('slider_single') ||
															selectedConstructor.includes('range')">
			<div class="serv__title serv__title--initial _m-0">
				<span>Counter option list</span>

				<div class="serv__option" @click="onSaveCounter">
					<v-icon name="edit"></v-icon>
				</div>
			</div>

			<div class="serv__table">
				<table>
					<thead>
					<tr>
						<th>Min position</th>
						<th>Max position</th>
						<th v-if="!selectedConstructor.includes('range')">Selected position</th>
						<template  v-if="selectedConstructor.includes('slider_single')">
							<th>Price, $</th>
							<th>Time, m(h)</th>
						</template>
						<template v-if="selectedConstructor.includes('range')">
							<th>Limit step</th>
							<th>Selected min position</th>
							<th>Selected max position</th>
						</template>
					</tr>
					</thead>

					<tbody>
						<tr>
							<td>{{counterCnf.counter_min || '-'}}</td>
							<td>{{counterCnf.counter_max || '-'}}</td>
							<td v-if="!selectedConstructor.includes('range')">{{counterCnf.counter_selected || '-'}}</td>
							<template v-if="selectedConstructor.includes('slider_single')">
								<td>
									<b v-if="counterCnf.price">{{counterCnf.price | numberNormalize}}</b>
								</td>
								<td>
									<template v-if="counterCnf.time">
										{{counterCnf.time | numberNormalize}}({{(counterCnf.time/60).toFixed(1) | numberNormalize}})
									</template>
								</td>
							</template>
							<template v-if="selectedConstructor.includes('range')">
								<td>{{counterCnf.limit}}</td>
								<td>{{counterCnf.selected_min}}</td>
								<td>{{counterCnf.selected_max}}</td>
							</template>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
	import 'vue-awesome/icons/plus';
	import 'vue-awesome/icons/trash-alt';
	import 'vue-awesome/icons/edit';
	import VIcon from 'vue-awesome/components/Icon';

	import InfoModal from '@components/InfoModal';
	import ServiceDataLvlSave from './ServiceDataLvlSave';
	import ServiceDataCounterSave from './ServiceDataCounterSave';
	import ServiceDataRangeSave from './ServiceDataRangeSave';

	import { createNamespacedHelpers } from 'vuex';
	const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('services');

	export default {
		name: 'service-data',
		props: ['lang'],
		components: {
			VIcon,
			InfoModal
		},

		computed: {
			...mapState([
				'service', 'CONSTRUCTOR_TYPES'
			]),
			selectedConstructor(){
				return this.CONSTRUCTOR_TYPES[this.service.item.layout_key] || [];
			},
			DEFAULT_LANG () {
				return this.$store.state.DEFAULT_LANG;
			},
			counterCnf(){
				if (!this.service.details.select) return {};
				return this.service.details.select.additional;
			},

			hasTranslate () {
				return this.lang.code !== this.DEFAULT_LANG.code
			},

			selectData(){
				return this.service.details.select
			}
		},

		methods: {
			...mapMutations([
				'DELETE_DATA_LVL'
			]),

			onSaveCounter(){
				this.$modal.show(ServiceDataCounterSave, {option: this.counterCnf}, $MODAL_OPTIONS.default);
			},

			onSave(option){
				this.$modal.show(ServiceDataLvlSave, {option: option, lang: this.lang}, $MODAL_OPTIONS.default);
			},

			onSaveRange(option){
				this.$modal.show(ServiceDataRangeSave, {option: option, lang: this.lang}, $MODAL_OPTIONS.default);
			},

			onDelete(option){
				if (confirm('Do you want delete lvl option?')){
					this.DELETE_DATA_LVL(option)
				}
			}
		}

	};
</script>

<style scoped lang=scss>
	@import '~@css/template/serv.scss';

	.serv__title{
		margin-bottom: 10px;
		&._m-0{
			margin-bottom: 0;
			border-bottom: none;
		}
	}
	.data{
		&__options{
			margin-bottom: 40px;
			&:last-child{
				margin-bottom: 40px;
			}
			&--counter{
				td{
					font-size: 20px;
					font-weight: bold;
				}

			}
		}
		&__ava{
			line-height: 0;
			text-align: center;
			img{
				max-width: 70px;
				max-height: 40px;
			}
		}
		&__table{
			padding-top: 10px;
		}
		&__footer{
			display: flex;
			justify-content: flex-end;
		}
		&__plus{
			display: flex;
			justify-content: center;
			height: 30px;
			padding: 0 5px;
			margin: 0 0 0 auto;
			align-items: center;
			border-bottom: 1px solid transparent;
			cursor: pointer;
			transition: all 0.3s;
			&:hover{
				border-bottom: 1px solid $c_alt;
				span{
					color: $c_alt;
				}
				svg{
					fill: $c_alt;
				}
			}
			span{
				transition: all 0.3s;
				margin-right: 5px;
			}
		}
	}

	.table{
		border-radius: 5px;
		box-shadow: 0 1px 8px rgba(0,0,0,0.3);
		.serv__tableOptions{
			transform: translateX(0px);
		}
		&__head{
			display: flex;
			background: $c_main;
			justify-content: space-between;
			border-radius: 5px 5px 0 0;
			box-shadow: 0 1px 8px rgba(0,0,0,0.3);
			.table__td{
				color: #fff;
				font-size: 12px;
				font-family: $f_alt;
			}
		}
		&__cnt{
			display: flex;
			width: 100%;
			align-items: center;
		}
		&__opt{
			flex-shrink: 0;
			width: 55px;
		}
		&__lvl{
			display: flex;
			align-items: center;
			position: relative;
			&.has-start{
				background: $c_alt_light;
			}
			&.has-end{
				background: rgba(76, 175, 80, 0.66);
			}
		}
		&__td{
			padding: 5px 8px;
			&--icon{
				width: 12%;
			}
			&--key{
				width: 22%;
			}
			&--name{
				width: 22%;
			}
			&--range{
				width: 22%;
				span{
					color: $c_alt;
				}
			}
			&--price{
				width: 22%;
			}
			&--time{
				width: 22%;
			}
		}
		&__dv{
			display: flex;
			align-items: stretch;
			border-radius: 0 0 5px 5px;
			.serv__table{
				width: 80%;
			}
			th{
				background: $c_border;
				color: $c_main;
			}
		}
		&__tr{
			margin-bottom: 20px;
			background: rgba(0,0,0,0.05);
			border-radius: 0 0 5px 5px;
		}
		&__dvTitle{
			width: 20%;
			padding-top: 24px;
			text-align: center;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			&:before{
				content: '';
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				height: 24px;
				background: $c_border;
				top: 0;
			}
			span{
				text-transform: uppercase;
				font-weight: bold;
				font-size: 16px;
				position: relative;
			}
		}
	}
</style>
