<template>
	<div class="module">
		<div class="module__logo">
			<img :src="require('@img/logo.png')" alt="">
		</div>
	</div>
</template>

<script>
	export default {
		name: 'dummy-module'
	};
</script>

<style scoped lang=scss>
	.module{
		position: relative;
		z-index: 1;
		height: 100%;
		display: flex;
		align-items: center;
	}
</style>
