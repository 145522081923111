<template>
	<div class="range">
		<div class="range__head">
			<div class="range__title">{{translation.range_title[lang.code]}}</div>
			<div class="range__wrap">
				<input type="number" class="range__area"
						:value="range[0]"
						@change="onChangeRange('min', $event)"
						@focus="$event.target.select()">

				<span>-</span>

				<input type="number" class="range__area"
						:value="range[1]"
						@change="onChangeRange('max',$event)"
						@focus="$event.target.select()">
			</div>
			<div class="range__line" ref="line"></div>
		</div>

		<vue-slider v-model="range"
				tooltip="none"
				@drag-end="onCheckValidRange($event)"
				:min="+counter.counter_min || 1"
				:max="+counter.counter_max || 10">
		</vue-slider>
	</div>
</template>

<script>
	import vueSlider from 'vue-slider-component';
	import 'vue-slider-component/theme/antd.css'

	import { createNamespacedHelpers } from 'vuex';
	const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('services');

	export default {
		name: 'range-module',
		components: {
			vueSlider
		},
		props: ['lang'],
		data(){
			return {
				range: [1, 100],
				limit: null
			}
		},

		computed:{
			...mapState([
				'service'
			]),

			translation () {
				return this.service.item.translations
			},
			counter(){
				return this.service.details.select ? this.service.details.select.additional : {};
			}
		},

		created(){
			this.setDefaultData();

			this.$emit('input',this.range);
			this.$emit('calculate');
		},

		methods: {
			onChangeRange(type, e){
				let num = +(e.target.value.replace(/\D/g, ''));
				switch(type){
					case 'min':
						this.range = [num > this.counter.counter_min ? num : +this.counter.counter_min, this.range[1]];
						break;
					case 'max':
						this.range = [this.range[0], num < this.counter.counter_max ? num : +this.counter.counter_max];
						break;
				}

				this.onCheckValidRange();


			},

			onCheckValidRange(param){
				let startVal = this.range[0],
					endVal =  this.range[1];

				if (endVal - startVal < this.limit){
					if (endVal - this.limit < this.counter.counter_min) {
						this.range = [startVal, startVal + this.limit];

						this.$emit('input',this.range);
						this.$emit('calculate');
						return false;
					}

					if (startVal + this.limit >= this.counter.counter_max){
						this.range = [ this.counter.counter_max - this.limit, this.counter.counter_max];
					} else {
						this.range = [startVal, startVal + this.limit];
					}

				}

				if (this.range[0] > this.counter.counter_max -1){
					this.range = [this.range[1] - 50, this.range[1]];
				}

				this.$emit('input',this.range);
				this.$emit('calculate');
			},

			setDefaultData(){
				this.range[0] = this.counter.selected_min;
				this.range[1] = this.counter.selected_max;

				this.limit = +this.counter.limit || 10;
			}
		}
	};
</script>

<style scoped lang=scss>
	.range{
		&__head{
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 5px;

		}
		&__title{
			font-family: $f_alt;
			text-transform: uppercase;
			text-align: center;
			font-weight: bold;
			font-size: 20px;
			margin-right: 10px;
			span{
				@media(max-width: $md){
					font-size: 14px;
				}
			}
		}
		&__wrap{
			display: flex;
			align-items: center;
		}
		&__area{
			border: none;
			border-bottom: 1px solid $c_border;
			width: 60px;
			padding: 3px 5px;
			margin: 0 10px;
			text-align: center;
		}
	}
</style>
