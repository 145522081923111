var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"serv"},[_c('div',{staticClass:"serv__title"},[_vm._v("Text in the calculator")]),_c('div',{staticClass:"serv__main"},[_c('div',{staticClass:"form__item"},[_c('form-text-edit',{attrs:{"label":("Left block title (" + (_vm.lang.code) + ")"),"translate":_vm.service.item.translations.block_one_title ?
						_vm.service.item.translations.block_one_title[this.DEFAULT_LANG.code] : null,"hasTranslate":_vm.hasTranslate,"name":"block_one_title"},model:{value:(_vm.block_one_title),callback:function ($$v) {_vm.block_one_title=$$v},expression:"block_one_title"}})],1),(_vm.selectedConstructor.includes('level') || _vm.selectedConstructor.includes('level_single'))?_c('div',{staticClass:"form__item"},[_c('form-text-edit',{attrs:{"label":("Current lvl title (" + (_vm.lang.code) + ")"),"translate":_vm.service.item.translations.current_lvl_title ?
						_vm.service.item.translations.current_lvl_title[this.DEFAULT_LANG.code] : null,"hasTranslate":_vm.hasTranslate,"name":"current_lvl_title"},model:{value:(_vm.current_lvl_title),callback:function ($$v) {_vm.current_lvl_title=$$v},expression:"current_lvl_title"}})],1):_vm._e(),(_vm.selectedConstructor.includes('division'))?_c('div',{staticClass:"form__item"},[_c('form-text-edit',{attrs:{"label":("Current division title (" + (_vm.lang.code) + ")"),"translate":_vm.service.item.translations.current_division_title ?
						_vm.service.item.translations.current_division_title[this.DEFAULT_LANG.code] : null,"hasTranslate":_vm.hasTranslate,"name":"current_division_title"},model:{value:(_vm.current_division_title),callback:function ($$v) {_vm.current_division_title=$$v},expression:"current_division_title"}})],1):_vm._e(),(!_vm.selectedConstructor.includes('slider_single') && !_vm.selectedConstructor.includes('level_single'))?_c('div',{staticClass:"form__item"},[_c('form-text-edit',{attrs:{"label":("Right block title (" + (_vm.lang.code) + ")"),"translate":_vm.service.item.translations.block_two_title ?
						_vm.service.item.translations.block_two_title[this.DEFAULT_LANG.code] : null,"hasTranslate":_vm.hasTranslate,"name":"block_two_title"},model:{value:(_vm.block_two_title),callback:function ($$v) {_vm.block_two_title=$$v},expression:"block_two_title"}})],1):_vm._e(),(_vm.selectedConstructor.includes('level') && !_vm.selectedConstructor.includes('slider'))?_c('div',{staticClass:"form__item"},[_c('form-text-edit',{attrs:{"label":("Desired lvl title (" + (_vm.lang.code) + ")"),"translate":_vm.service.item.translations.desired_lvl_title ?
						_vm.service.item.translations.desired_lvl_title[this.DEFAULT_LANG.code] : null,"hasTranslate":_vm.hasTranslate,"name":"desired_lvl_title"},model:{value:(_vm.desired_lvl_title),callback:function ($$v) {_vm.desired_lvl_title=$$v},expression:"desired_lvl_title"}})],1):_vm._e(),(_vm.selectedConstructor.includes('division') && !_vm.selectedConstructor.includes('slider'))?_c('div',{staticClass:"form__item"},[_c('form-text-edit',{attrs:{"label":("Desired division title (" + (_vm.lang.code) + ")"),"translate":_vm.service.item.translations.desired_division_title ?
						_vm.service.item.translations.desired_division_title[this.DEFAULT_LANG.code] : null,"hasTranslate":_vm.hasTranslate,"name":"desired_division_title"},model:{value:(_vm.desired_division_title),callback:function ($$v) {_vm.desired_division_title=$$v},expression:"desired_division_title"}})],1):_vm._e(),(_vm.selectedConstructor.includes('slider') || _vm.selectedConstructor.includes('slider_single'))?_c('div',{staticClass:"form__item"},[_c('form-text-edit',{attrs:{"label":("Counter title (" + (_vm.lang.code) + ")"),"translate":_vm.service.item.translations.counter_title ?
						_vm.service.item.translations.counter_title[this.DEFAULT_LANG.code] : null,"hasTranslate":_vm.hasTranslate,"name":"counter_title"},model:{value:(_vm.counter_title),callback:function ($$v) {_vm.counter_title=$$v},expression:"counter_title"}})],1):_vm._e(),(_vm.selectedConstructor.includes('range'))?_c('div',{staticClass:"form__item"},[_c('form-text-edit',{attrs:{"label":("Range title (" + (_vm.lang.code) + ")"),"translate":_vm.service.item.translations.range_title ?
						_vm.service.item.translations.range_title[this.DEFAULT_LANG.code] : null,"hasTranslate":_vm.hasTranslate,"name":"range_title"},model:{value:(_vm.range_title),callback:function ($$v) {_vm.range_title=$$v},expression:"range_title"}})],1):_vm._e(),(_vm.selectedConstructor.includes('range'))?_c('div',{staticClass:"form__item"},[_c('form-text-edit',{attrs:{"label":("Point title (" + (_vm.lang.code) + ")"),"translate":_vm.service.item.translations.point_title ?
						_vm.service.item.translations.point_title[this.DEFAULT_LANG.code] : null,"hasTranslate":_vm.hasTranslate,"name":"point_title"},model:{value:(_vm.point_title),callback:function ($$v) {_vm.point_title=$$v},expression:"point_title"}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }