<template>
	<div class="vmodal vmodal--ease">
		<button class="vmodal__close" @click="$emit('close')">
			<v-icon name="times"></v-icon>
		</button>

		<div class="vmodal__container">
			<div class="vmodal__main">
				<div class="form">
					<div class="form__head">
						<div class="form__icon">
							<v-icon name="cubes"></v-icon>
						</div>
						<div class="form__title">{{hasEdit ? 'Edit' : 'Create'}} champion</div>
					</div>

					<div class="grid">
						<div class="grid__coll grid__coll--6">
							<div class="form__item">
								<form-input v-model="name"
										:label="`Title (${lang.code})`"
										:translate="option ? option.name[this.DEFAULT_LANG.code] : null"
										:hasTranslate="hasTranslate"
										rules="required"
										@enter="onSave"
										name="name"></form-input>
							</div>

							<div class="form__item">
								<form-input v-model="key"
										:class="{'has-disabled':  hasEdit}"
										label="Key"
										rules="required"
										@enter="onSave"
										name="key"></form-input>
							</div>

							<div class="form__item">
								<form-checkbox v-model="is_selected"
										class="_m-0 has-sm"
										label="Default selected"
										name="is_selected"></form-checkbox>
							</div>
						</div>

						<div class="grid__coll grid__coll--6">
							<div class="form__item">
								<form-img v-model="image"
										label="Add icon"
										name="icon"></form-img>
							</div>
						</div>
					</div>

					<div class="form__footer">
						<button class="btn"
								@click="onSave"><span>{{hasEdit ? 'Edit' : 'Create'}}</span></button>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import {assign} from 'lodash';

	import 'vue-awesome/icons/times';
	import 'vue-awesome/icons/cubes';
	import VIcon from 'vue-awesome/components/Icon';

	import FormInput from '@form/FormInput';
	import FormImg from '@form/FormImg';
	import FormCheckbox from '@form/FormCheckbox';

	import { createNamespacedHelpers } from 'vuex';
	const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers('services');

	export default {
		name: 'champions-save',
		components: {
			VIcon,
			FormInput,
			FormCheckbox,
			FormImg,
		},
		props:['option', 'lang'],
		data(){
			return {
				name: null,
				key: null,
				image: null,
				is_selected: false,
			}
		},

		computed:{
			hasEdit(){
				return !!this.option;
			},

			DEFAULT_LANG(){
				return this.$store.state.DEFAULT_LANG;
			},

			hasTranslate(){
				return 	(this.lang.code !== this.DEFAULT_LANG.code) && this.hasEdit
			},
		},

		created(){
			if(this.hasEdit) this.buildData();
		},

		methods: {
			...mapMutations([
				'SAVE_CHAMPION'
			]),

			onSave(){
				this.$validator.validate().then(result => {
					if (result) {
						this.SAVE_CHAMPION({
							id: this.hasEdit ? this.option.id : null,
							name: assign(this.option ? this.option.name : {}, {
								[this.lang.code]: this.name
							}),
							key: this.key,
							image: this.image,
							is_selected: this.is_selected
						});
						this.$emit('close');
					}
				});
			},

			buildData(){
				let option = this.option;

				this.name = option.name[this.lang.code];
				this.key = option.key;
				this.image = option.image;
				this.is_selected = +option.is_selected;
			}
		}
	};
</script>

<style scoped lang=scss>

</style>
