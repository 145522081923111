<template>
	<div class="vmodal">
		<button class="vmodal__close" @click="$emit('close')">
			<v-icon name="times"></v-icon>
		</button>

		<div class="vmodal__container">
			<div class="vmodal__main">
				<calc-index :lang="lang"></calc-index>
			</div>
		</div>
	</div>
</template>

<script>
	import 'vue-awesome/icons/times';
	import VIcon from 'vue-awesome/components/Icon';

	import CalcIndex from './calc/CalcIndex';

	export default {
		name: 'service-preview',
		components: {
			VIcon,
			CalcIndex
		},
		props:['lang']
	};
</script>

<style scoped lang=scss>
	.vmodal{
		&__main{
			padding: 0;
		}
	}
</style>
